<template>
  <b-card
    class="blog-edit-wrapper"
    style="    text-align: center;"
  >
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5 mb-5">
        Build new Sitemap

        <feather-icon icon="Edit2Icon" />
      </h4>
      <h5> When you click on "Generate Sitemap" button, you will build a new sitemap for the website :</h5>
    </div>
    <b-button

      variant="primary"
      class="mt-5"
      @click="save"
    >
      GENERATE SITEMAP
    </b-button>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BButton,
} from 'bootstrap-vue'

import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const save = () => {
      store
        .dispatch('setting/GenerateMap')
        .then(response => {
          Vue.swal({
            title: 'Generated',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          Vue.swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    }
    return {

      save,

    }
  },

  components: {
    BCard,

    BButton,
  },

  directives: {
    //   Ripple,
  },
}
</script>

  <style lang="scss">
    .col-12 {
      margin: 0px !important;
    }
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
